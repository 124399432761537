import React, { useState, useEffect } from 'react';

import Input from '../components/Input';
import PageHeader from '../components/PageHeader';
import { Card, Button, Loader, Switch, Alert, Select } from '@mantine/core';
import { createPublicCircl, getFixtures, insertPush } from '../utils/api';
import moment from 'moment';

const CreatePublicCircl = () => {
	const [loading, setLoading] = useState(true);
	const [showAlert, setShowAlert] = useState(false);
	const [request, setRequest] = useState({
		free: false,
		display: false,
	});
	const [fixtures, setFixtures] = useState([]);
	const gameTypes = [
		{ label: 'Shifter', value: 3 },
		{ label: 'Goal Gifter', value: 4 },
		{ label: 'Countdown', value: 5 },
		{ label: 'Jackpot', value: 6 },
	];

	useEffect(() => {
		init();

		async function init() {
			const [fixtures] = await Promise.all([
				getFixtures(
					moment().startOf('day').toISOString(),
					moment().add(1, 'years').endOf('day').toISOString()
				),
			]);

			setFixtures(
				fixtures.map((x) => {
					return {
						label: `${x.description} (${x.homeCode} v ${
							x.awayCode
						}) - ${moment(x.matchDateTime).format('DD/MM/YYYY')}`,
						value: {
							matchId: x.matchId,
							tournamentId: x.tournamentId,
						},
					};
				})
			);

			setLoading(false);
		}
	}, []);

	async function save() {
		setLoading(true);

		try {
			await createPublicCircl(request);
			window.location.href = '/public-circls';
		} catch (e) {
			console.error(e);
			setShowAlert(true);
		}
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Push Notification Schedule'} />
			{showAlert && <Alert title="Something went wrong!" color="red" />}

			<Card shadow={'md'}>
				<Select
					label="Fixture"
					placeholder="Pick one"
					data={fixtures}
					searchable
					onChange={(value) =>
						setRequest({
							...request,
							matchId: value.matchId,
							tournamentId: value.tournamentId,
						})
					}
				/>

				<Input
					label={'Name'}
					onChange={(event) =>
						setRequest({
							...request,
							name: event.target.value,
						})
					}
				/>

				<Input
					label={'Buy in'}
					onChange={(event) =>
						setRequest({
							...request,
							buyIn: event.target.value,
						})
					}
				/>

				<Input
					label={'Pot'}
					onChange={(event) =>
						setRequest({
							...request,
							pot: event.target.value,
						})
					}
				/>

				<Input
					label={'Max Players'}
					onChange={(event) =>
						setRequest({
							...request,
							maxPlayers: event.target.value,
						})
					}
				/>

				<Select
					label="Game Type"
					placeholder="Pick one"
					data={gameTypes}
					searchable
					onChange={(value) =>
						setRequest({
							...request,
							mechanismType: value,
						})
					}
				/>

				<Switch
					label="Free"
					style={{ margin: '20px 0' }}
					onChange={(event) =>
						setRequest({
							...request,
							free: event.currentTarget.checked,
						})
					}
				/>

				<Switch
					label="Display"
					style={{ margin: '20px 0' }}
					onChange={(event) =>
						setRequest({
							...request,
							display: event.currentTarget.checked,
						})
					}
				/>

				<Button
					onClick={save}
					disabled={!request.matchId || !request.name}
					style={{ margin: '20px 0' }}
				>
					Save
				</Button>
			</Card>
		</>
	);
};

export default CreatePublicCircl;
