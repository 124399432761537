import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Input, Button, Loader } from '@mantine/core';
import PageHeader from '../components/PageHeader';
import { TableStyles } from '../styles/DefaultTheme';
import { getUsers } from '../utils/api';
import moment from 'moment';

const SearchRow = styled.div`
	display: grid;
	grid-template-columns: 3fr 1fr;
	align-items: center;
	margin-bottom: 20px;
`;

const Users = () => {
	const [users, setUsers] = useState([]);
	const [searchQuery, setSearchQuery] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getData();
	}, []);

	async function getData() {
		const users = await getUsers(searchQuery);
		setUsers(users);
		setLoading(false);
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Search Users'} />

			<SearchRow>
				<Input
					style={{ marginRight: 20 }}
					placeholder={'surname OR email OR username'}
					value={searchQuery}
					onChange={(event) => setSearchQuery(event.target.value)}
					onKeyPress={(event) => {
						if (event.key === 'Enter') {
							getData();
						}
					}}
				/>
				<Button onClick={getData}>Search</Button>
			</SearchRow>

			<Table
				striped={true}
				highlightOnHover={true}
				style={TableStyles.clickable}
			>
				<thead>
					<tr>
						<th>Forename</th>
						<th>Surname</th>
						<th>DOB</th>
						<th>Email</th>
						<th>Username</th>
					</tr>
				</thead>
				<tbody>
					{users.map((user, index) => {
						return (
							<tr
								onClick={() =>
									(window.location.href = `/user/${user.userId}`)
								}
								key={index}
							>
								<td>{user.forename}</td>
								<td>{user.surname}</td>
								<td>{moment(user.dob).format('DD/MM/yyyy')}</td>
								<td>{user.email}</td>
								<td>{user.username}</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</>
	);
};

export default Users;
