import moment from 'moment';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import {
	Card,
	Table,
	Button,
	Checkbox,
	Loader,
	Badge,
	Modal,
	InputWrapper,
} from '@mantine/core';
import Field from '../components/Field';
import { DefaultTheme, TableStyles } from '../styles/DefaultTheme';
import {
	createNote,
	getUser,
	postFreeBet,
	updateUserSelfExclusion,
	updateUserStatus,
	deleteUser,
	deleteCard,
	updateUserIDV,
} from '../utils/api';
import {
	formatCurrency,
	formatDate,
	formatDateTime,
	getGameType,
	getPeriod,
	getTransactionType,
} from '../utils/utils';
import Input from '../components/Input';

const FieldGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;

	@media (max-width: 500px) {
		grid-template-columns: 1fr;
	}
`;

const UserProfile = () => {
	let { userId } = useParams();
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(true);
	const [showAddFreeBet, setShowAddFreeBet] = useState(false);
	const [showAddNote, setShowAddNote] = useState(false);
	const [showSelfExcludeModal, setShowSelfExcludeModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [freeBetRequest, setFreeBetRequest] = useState({
		userId,
		host: false,
		claim: false,
	});
	const [selfExclusionEndDate, setSelfExclusionEndDate] = useState();
	const [note, setNote] = useState();

	useEffect(() => {
		getData();
	}, []);

	async function getData() {
		const user = await getUser(userId);
		setUser(user);
		setLoading(false);
	}

	async function createFreeBet() {
		setLoading(true);

		await postFreeBet({
			...freeBetRequest,
			expiry: freeBetRequest.expiry
				? moment(freeBetRequest.expiry).endOf('day').toISOString()
				: null,
		});

		await getData();
		setShowAddFreeBet(false);
	}

	async function onCreateNote() {
		setLoading(true);

		await createNote(userId, note);

		setNote(null);
		setShowAddNote(false);
		await getData();
	}

	async function onDeleteCard(cardId) {
		setLoading(true);

		await deleteCard(cardId);

		await getData();
	}

	async function onUpdateAccountStatus(status) {
		setLoading(true);

		await updateUserStatus(userId, status);

		await getData();
	}

	async function onSetSelfExclusion() {
		setLoading(true);

		await updateUserSelfExclusion(userId, selfExclusionEndDate);
		setShowSelfExcludeModal(false);

		await getData();
	}

	async function onUpdateUserIDV() {
		setLoading(true);

		await updateUserIDV(userId);

		await getData();
	}

	async function onDelete() {
		setLoading(true);

		await deleteUser(userId);
		window.location.href = `/users`;
	}

	function getRowColor(circl) {
		if (!circl.endTime) {
			if (circl.startTime) {
				return DefaultTheme.colors.primary.blue;
			} else {
				return DefaultTheme.colors.shades[70];
			}
		} else if (circl.winnerUserId === parseInt(userId)) {
			return DefaultTheme.colors.secondary.green;
		} else {
			return DefaultTheme.colors.secondary.red;
		}
	}

	function renderGamStopStatus() {
		let text;
		let color;

		switch (user.gamStopStatus) {
			case 0:
				text = 'GamStop - Not Run';
				color = 'gray';
				break;
			case 1:
				text = 'GamStop - No';
				color = 'green';
				break;
			case 2:
				text = 'GamStop - Yes';
				color = 'red';
				break;
			case 3:
				text = 'GamStop - Previously';
				color = 'orange';
				break;
			case 4:
				text = 'GamStop - Error';
				color = 'red';
				break;
		}

		return (
			<Badge variant="filled" color={color}>
				{text}
			</Badge>
		);
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<Modal
				opened={showSelfExcludeModal}
				onClose={() => setShowSelfExcludeModal(false)}
				title="Self Exclusion"
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<div>
						<span>End Date: </span>
						<DatePicker
							dateFormat="dd/MM/yyyy"
							selected={selfExclusionEndDate}
							onChange={(date) => setSelfExclusionEndDate(date)}
						/>
					</div>
					<Button onClick={onSetSelfExclusion}>Save</Button>
				</div>
			</Modal>

			<Modal
				opened={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				title="Delete User"
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<div>
						<span>Are you sure you want to delete this user?</span>
					</div>
					<Button
						onClick={onDelete}
						color={'red'}
						style={{ marginRight: 20 }}
					>
						Yes
					</Button>
					<Button onClick={() => setShowDeleteModal(false)}>
						No
					</Button>
				</div>
			</Modal>

			<PageHeader title={'User Profile'} />
			<Card shadow={'md'}>
				{user.accountDisabled && (
					<Badge
						variant="filled"
						color={'red'}
						style={{ marginRight: 20 }}
					>
						Account Disabled
					</Badge>
				)}

				{user.selfExclusionEndDate &&
					new Date(user.selfExclusionEndDate) > new Date() && (
						<Badge variant="filled" color={'red'}>
							Self Excluded until{' '}
							{formatDateTime(user.selfExclusionEndDate)}
						</Badge>
					)}

				{user.coolOffDate &&
					new Date(user.coolOffDate) > new Date() && (
						<Badge variant="filled" color={'red'}>
							Cooling off until {formatDateTime(user.coolOffDate)}
						</Badge>
					)}

				{renderGamStopStatus()}

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<h2>Account</h2>

					<div>
						<Button
							onClick={() => setShowSelfExcludeModal(true)}
							style={{ marginRight: 20 }}
						>
							Self Exclude
						</Button>

						{user.accountDisabled ? (
							<Button
								color={'green'}
								onClick={() => onUpdateAccountStatus(false)}
							>
								Enable Account
							</Button>
						) : (
							<Button
								color={'orange'}
								onClick={() => onUpdateAccountStatus(true)}
							>
								Disable Account
							</Button>
						)}

						<Button
							onClick={() => setShowDeleteModal(true)}
							style={{ marginLeft: 20 }}
							color={'red'}
						>
							Delete User
						</Button>
					</div>
				</div>

				<FieldGrid>
					<Field label={'Forename'} value={user.forename} />
					<Field label={'Surname'} value={user.surname} />
					<Field
						label={'Date of birth'}
						value={moment(user.dob).format('DD/MM/yyyy')}
					/>
					<Field label={'Email'} value={user.email} />
					<Field label={'Username'} value={user.username} />
					<Field
						label={'Account Balance'}
						value={formatCurrency(user.accountBalance)}
					/>
					<Field
						label={'Marketing Opt In'}
						value={user.marketingOptIn ? 'Yes' : 'No'}
					/>
					<Field />
					<Field label={'Address Line 1'} value={user.line1} />
					<Field label={'Address Line 2'} value={user.line2} />
					<Field label={'City'} value={user.city} />
					<Field label={'Postcode'} value={user.postcode} />
					<Field
						label={'Created On'}
						value={formatDateTime(user.createdOn)}
					/>
					<Field
						label={'Confirmed Email Date'}
						value={formatDateTime(user.confirmedEmailDate)}
					/>
					<Field
						label={'Last Logged In'}
						value={formatDateTime(user.lastLoggedIn)}
					/>
					<Field label={'Referral Code'} value={user.referralCode} />
					<Field label={'UTM Source'} value={user.utmSource} />
					<Field label={'UTM Campaign'} value={user.utmCampaign} />
				</FieldGrid>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<h2>Notes</h2>

					{!showAddNote && (
						<Button onClick={() => setShowAddNote(true)}>
							Add
						</Button>
					)}
				</div>

				{showAddNote && (
					<div
						style={{
							border: 'solid 1px #dad4d4',
							padding: 20,
							borderRadius: 8,
						}}
					>
						<InputWrapper label="Note">
							<textarea
								style={{
									width: '100%',
									marginBottom: 20,
									fontFamily: 'Red Hat Display',
									padding: 10,
								}}
								rows={5}
								onChange={(event) =>
									setNote(event.target.value)
								}
							/>
						</InputWrapper>
						<Button
							style={{ marginRight: 20 }}
							onClick={onCreateNote}
						>
							Confirm
						</Button>
						<Button
							color={'red'}
							onClick={() => setShowAddNote(false)}
						>
							Cancel
						</Button>
					</div>
				)}

				{user.notes?.length > 0 ? (
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: 300,
							display: 'block',
						}}
					>
						<Table striped={true} highlightOnHover={true}>
							<thead>
								<tr>
									<th>Note</th>
									<th>Created On</th>
								</tr>
							</thead>
							<tbody>
								{user.notes.map((note) => {
									return (
										<tr>
											<td>{note.text}</td>
											<td>
												{moment(note.createdOn).format(
													'DD/MM/yyyy HH:mm'
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				) : (
					<div>No notes yet</div>
				)}

				<h2>Circl's ({user.circls?.length || 0})</h2>

				{user.circls?.length > 0 ? (
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: 300,
							display: 'block',
						}}
					>
						<Table
							style={{
								color: '#FFFFFF',
								...TableStyles.clickable,
							}}
						>
							<thead>
								<tr>
									<th>Name</th>
									<th>Match</th>
									<th>Stake</th>
									<th>Pot</th>
									<th>Players</th>
									<th>Host</th>
									<th>Created On</th>
									<th>Game Type</th>
									<th>Period</th>
								</tr>
							</thead>
							<tbody>
								{user.circls.map((circl) => {
									return (
										<tr
											onClick={() =>
												(window.location.href = `/circl/${circl.circlId}`)
											}
											style={{
												backgroundColor:
													getRowColor(circl),
											}}
										>
											<td>{circl.name}</td>
											<td>{circl.description}</td>
											<td>
												{formatCurrency(circl.buyIn)}
											</td>
											<td>
												{formatCurrency(
													circl.buyIn *
														circl.numberOfPlayers
												)}
											</td>
											<td>{circl.numberOfPlayers}</td>
											<td>{circl.host ? 'Yes' : 'No'}</td>
											<td>
												{moment(circl.createdOn).format(
													'DD/MM/yyyy HH:mm'
												)}
											</td>
											<td>
												{getGameType(circl.gameType)}
											</td>
											<td>{getPeriod(circl.period)}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				) : (
					<div>No Circls played yet</div>
				)}

				<h2>FMM Tickets ({user.lotteryTickets?.length || 0})</h2>

				{user.lotteryTickets?.length > 0 ? (
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: 300,
							display: 'block',
						}}
					>
						<Table highlightOnHover={true} striped={true}>
							<thead>
								<tr>
									<th>Lottery ID</th>
									<th>Ticket ID</th>
									<th>Start Date</th>
									<th>Game Week</th>
									<th>Weeks</th>
									<th>Win Amount</th>
									<th>End Time</th>
									<th>Created On</th>
								</tr>
							</thead>
							<tbody>
								{user.lotteryTickets.map((ticket) => {
									return (
										<tr>
											<td>{ticket.lotteryId}</td>
											<td>{ticket.ticketId}</td>
											<td>
												{moment(
													ticket.startDate
												).format('DD/MM/yyyy HH:mm')}
											</td>
											<td>{ticket.gameWeek}</td>
											<td>{ticket.weeks}</td>
											<td>
												{formatCurrency(
													ticket.winAmount
												)}
											</td>
											<td>
												{moment(ticket.endTime).format(
													'DD/MM/yyyy HH:mm'
												)}
											</td>
											<td>
												{moment(
													ticket.createdOn
												).format('DD/MM/yyyy HH:mm')}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				) : (
					<div>No FMM entries yet</div>
				)}

				<h2>FMM Bundles ({user.lotteryBundles?.length || 0})</h2>

				{user.lotteryBundles?.length > 0 ? (
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: 300,
							display: 'block',
						}}
					>
						<Table highlightOnHover={true} striped={true}>
							<thead>
								<tr>
									<th>Ticket ID's</th>
									<th>Price</th>
									<th>Weeks</th>
									<th>Weeks Remaining</th>
									<th>Created On</th>
								</tr>
							</thead>
							<tbody>
								{user.lotteryBundles.map((bundle) => {
									return (
										<tr>
											<td>
												{bundle.ticketIds.join(', ')}
											</td>
											<td>
												{formatCurrency(bundle.amount)}
											</td>
											<td>{bundle.weeks}</td>
											<td>{bundle.weeksRemaining}</td>
											<td>
												{moment(
													bundle.createdOn
												).format('DD/MM/yyyy HH:mm')}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				) : (
					<div>No FMM entries yet</div>
				)}

				<h2>Transactions</h2>

				{user.transactions?.length > 0 ? (
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: 300,
							display: 'block',
						}}
					>
						<Table striped={true} highlightOnHover={true}>
							<thead>
								<tr>
									<th>Type</th>
									<th>Amount</th>
									<th>Date</th>
								</tr>
							</thead>
							<tbody>
								{user.transactions.map((transaction) => {
									return (
										<tr>
											<td>
												{getTransactionType(
													transaction.type
												)}
											</td>
											<td>
												{formatCurrency(
													transaction.amount
												)}
											</td>

											<td>
												{moment(
													transaction.createdOn
												).format('DD/MM/YYYY HH:mm')}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				) : (
					<div>No Transactions</div>
				)}

				<h2>Transaction Errors</h2>

				<Button
					onClick={() =>
						window.open(
							'https://developer.worldpay.com/docs/access-worldpay/reference/useful-tables#refusal-codes',
							'_blank'
						)
					}
				>
					View Error Codes
				</Button>

				{user.paymentErrors?.length > 0 ? (
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: 300,
							display: 'block',
						}}
					>
						<Table striped={true} highlightOnHover={true}>
							<thead>
								<tr>
									<th>Correlation ID</th>
									<th>Outcome</th>
									<th>Description</th>
									<th>Refusal Code</th>
									<th>Refusal Advice Code</th>
									<th>Created On</th>
								</tr>
							</thead>
							<tbody>
								{user.paymentErrors.map((error) => {
									return (
										<tr>
											<td>{error.correlationId}</td>
											<td>{error.outcome}</td>
											<td>{error.description}</td>
											<td>{error.code}</td>
											<td>{error.refusalAdvice}</td>
											<td>
												{moment(error.createdOn).format(
													'DD/MM/YYYY HH:mm'
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				) : (
					<div>No Transaction errors</div>
				)}

				<h2>IDV Status</h2>

				<Button onClick={onUpdateUserIDV}>Manual IDV Pass</Button>

				{user.idvStatus?.length > 0 ? (
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: 300,
							display: 'block',
						}}
					>
						<Table striped={true} highlightOnHover={true}>
							<thead>
								<tr>
									<th>Check ID</th>
									<th>Status</th>
									<th>Type</th>
									<th>Created On</th>
								</tr>
							</thead>
							<tbody>
								{user.idvStatus.map((idv) => {
									return (
										<tr
											style={{
												backgroundColor:
													idv.status == 'pass'
														? DefaultTheme.colors
																.secondary.green
														: DefaultTheme.colors
																.secondary.red,
											}}
										>
											<td>{idv.checkId}</td>
											<td>{idv.status}</td>
											<td>
												{idv.checkType === 1
													? 'DB'
													: 'Document'}
											</td>
											<td>
												{formatDateTime(idv.createdOn)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				) : (
					<div>No IDV Records</div>
				)}

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<h2>Free Bets</h2>

					{!showAddFreeBet && (
						<Button onClick={() => setShowAddFreeBet(true)}>
							Add
						</Button>
					)}
				</div>

				{showAddFreeBet && (
					<FieldGrid
						style={{
							border: 'solid 1px #dad4d4',
							padding: 20,
							borderRadius: 8,
						}}
					>
						<Input
							label={'Amount'}
							placeholder={'Amount'}
							onChange={(event) =>
								setFreeBetRequest({
									...freeBetRequest,
									amount: event.target.value,
								})
							}
						/>
						<Input
							label={'Code'}
							placeholder={'Code'}
							onChange={(event) =>
								setFreeBetRequest({
									...freeBetRequest,
									code: event.target.value,
								})
							}
						/>
						<div>
							<span>Expiry: </span>
							<DatePicker
								dateFormat="dd/MM/yyyy"
								selected={freeBetRequest.expiry}
								onChange={(date) =>
									setFreeBetRequest({
										...freeBetRequest,
										expiry: date,
									})
								}
							/>
						</div>
						<Checkbox
							label={'Host only?'}
							checked={freeBetRequest.host}
							onChange={(event) =>
								setFreeBetRequest({
									...freeBetRequest,
									host: event.currentTarget.checked,
								})
							}
						/>
						<span></span>
						<Checkbox
							label={'Claim Now'}
							checked={freeBetRequest.claim}
							onChange={(event) =>
								setFreeBetRequest({
									...freeBetRequest,
									claim: event.currentTarget.checked,
								})
							}
						/>
						<Button onClick={createFreeBet}>Confirm</Button>
						<Button
							color={'red'}
							onClick={() => setShowAddFreeBet(false)}
						>
							Cancel
						</Button>
					</FieldGrid>
				)}

				{user.freeBets?.length > 0 ? (
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: 300,
							display: 'block',
						}}
					>
						<Table
							striped={true}
							highlightOnHover={true}
							style={{ marginTop: 20 }}
						>
							<thead>
								<tr>
									<th>Amount</th>
									<th>Code</th>
									<th>Host</th>
									<th>Expiry</th>
									<th>Claimed</th>
									<th>Claimed On</th>
									<th>Used</th>
									<th>Used On</th>
									<th>Created On</th>
								</tr>
							</thead>
							<tbody>
								{user.freeBets.map((freeBet) => {
									return (
										<tr>
											<td>
												{formatCurrency(freeBet.amount)}
											</td>
											<td>{freeBet.code}</td>
											<td>
												{freeBet.hostOnly
													? 'Yes'
													: 'No'}
											</td>
											<td>
												{formatDate(freeBet.expiry)}
											</td>
											<td>
												{freeBet.claimed ? 'Yes' : 'No'}
											</td>
											<td>
												{freeBet.claimedOn
													? moment(
															freeBet.claimedOn
													  ).format('DD/MM/YYYY')
													: 'N/A'}
											</td>
											<td>
												{freeBet.used ? 'Yes' : 'No'}
											</td>
											<td>
												{freeBet.usedOn
													? moment(
															freeBet.usedOn
													  ).format('DD/MM/YYYY')
													: 'N/A'}
											</td>
											<td>
												{moment(
													freeBet.createdOn
												).format('DD/MM/YYYY')}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				) : (
					<div>No free bets</div>
				)}

				<h2>Cards</h2>

				{user.cards?.length > 0 ? (
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: 300,
							display: 'block',
						}}
					>
						<Table striped={true} highlightOnHover={true}>
							<thead>
								<tr>
									<th>Card Number</th>
									<th>Expiry</th>
									<th>Added On</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{user.cards.map((card) => {
									return (
										<tr>
											<td>**** {card.number}</td>
											<td>{card.expiry}</td>

											<td>
												{moment(card.createdOn).format(
													'DD/MM/YYYY'
												)}
											</td>
											<Button
												onClick={() =>
													onDeleteCard(card.cardId)
												}
												color={'red'}
											>
												Delete Card
											</Button>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				) : (
					<div>No Cards</div>
				)}

				<h2>Friends</h2>

				{user.friends?.length > 0 ? (
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: 300,
							display: 'block',
						}}
					>
						<Table striped={true} highlightOnHover={true}>
							<thead>
								<tr>
									<th>Forename</th>
									<th>Surname</th>
									<th>Email</th>
								</tr>
							</thead>
							<tbody>
								{user.friends.map((friend) => {
									return (
										<tr
											onClick={() =>
												(window.location.href = `/user/${friend.userId}`)
											}
										>
											<td>{friend.forename}</td>
											<td>{friend.surname}</td>

											<td>{friend.email}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				) : (
					<div>No Friends :(</div>
				)}

				<h2>Preferences</h2>

				<FieldGrid>
					<Field
						label={'General Notifications'}
						value={user.generalNotifications ? 'Yes' : 'No'}
					/>

					<Field
						label={'Event Notifications'}
						value={user.eventNotifications ? 'Yes' : 'No'}
					/>

					<Field
						label={'Chat Notifications'}
						value={user.chatNotifications ? 'Yes' : 'No'}
					/>
				</FieldGrid>
			</Card>
		</>
	);
};

export default UserProfile;
