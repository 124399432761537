import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageHeader from '../components/PageHeader';
import { Loader, Table } from '@mantine/core';
import { getLotteryDashboard } from '../utils/api';
import { formatCurrency } from '../utils/utils';
import moment from 'moment';

const DashContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const TableStyled = styled(Table)`
	display: block;
	overflow-x: auto;
	white-space: nowrap;
	width: 100%;
`;

const TableHeader = styled.th`
	font-weight: 500;
	margin: 0 10px;
`;

const LotteryDashboard = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function init() {
			const data = await getLotteryDashboard();
			setData(data);
			setLoading(false);
		}

		init();
	}, []);

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Five Match Millions'} />

			<DashContainer>
				<TableStyled
					striped
					highlightOnHover
					withBorder
					withColumnBorders
				>
					<thead>
						<tr>
							<TableHeader>Lottery ID</TableHeader>
							<TableHeader>Total Tickets</TableHeader>
							<TableHeader>Unique Users</TableHeader>
							<TableHeader>Start Date</TableHeader>
							<TableHeader>End Date</TableHeader>
							<TableHeader>Cash Stakes</TableHeader>
							<TableHeader>Free Bet Stakes</TableHeader>
							<TableHeader>Total Pool</TableHeader>
							<TableHeader>Circl Rake</TableHeader>
							<TableHeader>RTP Pool</TableHeader>
							<TableHeader>Total Paid Out</TableHeader>
							<TableHeader>Rollover</TableHeader>
							<TableHeader>Match 1 Count</TableHeader>
							<TableHeader>Match 1 Dividend</TableHeader>
							<TableHeader>Match 2 Count</TableHeader>
							<TableHeader>Match 2 Dividend</TableHeader>
							<TableHeader>Match 3 Count</TableHeader>
							<TableHeader>Match 3 Dividend</TableHeader>
							<TableHeader>Match 4 Count</TableHeader>
							<TableHeader>Match 4 Dividend</TableHeader>
							<TableHeader>Match 5 Count</TableHeader>
							<TableHeader>Match 5 Dividend</TableHeader>
						</tr>
					</thead>
					<tbody>
						{data.map((lottery) => {
							return (
								<tr>
									<td>{lottery.lotteryId}</td>
									<td>{lottery.entryCount}</td>
									<td>{lottery.uniqueEntryCount}</td>
									<td>
										{moment(lottery.startDate).format(
											'DD/MM/YYYY HH:mm'
										)}
									</td>
									<td>
										{moment(lottery.endTime).format(
											'DD/MM/YYYY HH:mm'
										)}
									</td>
									<td>
										{formatCurrency(lottery.cashStakes)}
									</td>
									<td>
										{formatCurrency(lottery.freeBetStakes)}
									</td>
									<td>{formatCurrency(lottery.totalPool)}</td>
									<td>{formatCurrency(lottery.circlRake)}</td>
									<td>{formatCurrency(lottery.rtpPool)}</td>
									<td>
										{formatCurrency(lottery.totalPaidOut)}
									</td>
									<td>{formatCurrency(lottery.rollover)}</td>
									<td>{lottery.match1Count}</td>
									<td>
										{formatCurrency(
											lottery.match1PayoutPer
										)}
									</td>
									<td>{lottery.match2Count}</td>
									<td>
										{formatCurrency(
											lottery.match2PayoutPer
										)}
									</td>
									<td>{lottery.match3Count}</td>
									<td>
										{formatCurrency(
											lottery.match3PayoutPer
										)}
									</td>
									<td>{lottery.match4Count}</td>
									<td>
										{formatCurrency(
											lottery.match4PayoutPer
										)}
									</td>
									<td>{lottery.match5Count}</td>
									<td>
										{formatCurrency(
											lottery.match5PayoutPer
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</TableStyled>
			</DashContainer>
		</>
	);
};

export default LotteryDashboard;
