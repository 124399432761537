import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Loader, Button } from '@mantine/core';
import PageHeader from '../components/PageHeader';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import { TableStyles } from '../styles/DefaultTheme';
import { getFixtures } from '../utils/api';

const SearchRow = styled.div`
	display: grid;
	grid-template-columns: 2fr 2fr 1fr;
	align-items: center;
	margin-bottom: 20px;

	@media (max-width: 500px) {
		grid-template-columns: 1fr;
		gap: 20px;
	}

	span {
		margin-right: 20px;
	}
`;

const Fixtures = () => {
	const [fromDate, setFromDate] = useState(new Date());
	const [toDate, setToDate] = useState(moment().add(14, 'days').toDate());
	const [fixtures, setFixtures] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getData();
	}, []);

	async function getData() {
		const fixtures = await getFixtures(
			moment(fromDate).startOf('day').toISOString(),
			moment(toDate).endOf('day').toISOString()
		);
		setFixtures(fixtures);
		setLoading(false);
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Fixtures'} />

			<SearchRow>
				<div>
					<span>From:</span>
					<DatePicker
						selected={fromDate}
						onChange={(date) => setFromDate(date)}
						dateFormat="dd/MM/yyyy"
					/>
				</div>
				<div>
					<span>To:</span>
					<DatePicker
						selected={toDate}
						onChange={(date) => setToDate(date)}
						dateFormat="dd/MM/yyyy"
					/>
				</div>
				<Button onClick={getData}>Search</Button>
			</SearchRow>

			<Table
				striped={true}
				highlightOnHover={true}
				style={TableStyles.clickable}
			>
				<thead>
					<tr>
						<th>Description</th>
						<th>Match Time</th>
						<th>Home Score</th>
						<th>Away Score</th>
						<th>Status</th>
						<th>Venue</th>
						<th>Started</th>
						<th>Featured</th>
						<th>Channel</th>
					</tr>
				</thead>
				<tbody>
					{fixtures.map((fixture) => {
						return (
							<tr
								onClick={() =>
									(window.location.href = `/fixture/${fixture.matchId}`)
								}
							>
								<td>{fixture.description}</td>
								<td>
									{moment(fixture.matchDateTime).format(
										'DD/MM/YYYY HH:mm'
									)}
								</td>
								<td>
									{fixture.started ? fixture.homeScore : '-'}
								</td>
								<td>
									{fixture.started ? fixture.awayScore : '-'}
								</td>
								<td>{fixture.status}</td>
								<td>{fixture.venue}</td>
								<td>{fixture.started ? 'Yes' : 'No'}</td>
								<td>{fixture.featured ? 'Yes' : 'No'}</td>
								<td>{fixture.channel}</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</>
	);
};

export default Fixtures;
