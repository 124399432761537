import { Auth } from 'aws-amplify';
import config from './config';

export async function getUsers(searchQuery) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.crmApiUrl}/users?query=${searchQuery || ''}`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error('Failed to get users');
	}

	return await response.json();
}

export async function getUser(userId) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/users/${userId}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to get user');
	}

	return await response.json();
}

export async function getFixtures(from, to) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.crmApiUrl}/fixtures?from=${from}&to=${to}`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error('Failed to get fixtures');
	}

	return await response.json();
}

export async function getLotteries() {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.crmApiUrl}/lotteries`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error('Failed to get lotteries');
	}

	return await response.json();
}

export async function getPublicCircls(from, to) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.crmApiUrl}/circls/public?from=${from}&to=${to}`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error('Failed to get public circls');
	}

	return await response.json();
}

export async function getFixture(matchId) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/fixtures/${matchId}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to get fixture');
	}

	return await response.json();
}

export async function updateFixture(matchId, request) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/fixtures/${matchId}`, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
		body: JSON.stringify(request),
	});

	if (!response.ok) {
		throw new Error('Failed to update fixture');
	}
}

export async function postFreeBet(request) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/free-bets`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
		body: JSON.stringify(request),
	});

	if (!response.ok) {
		throw new Error('Failed to create free bet');
	}
}

export async function getCircl(circlId) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/circls/${circlId}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to get circl');
	}

	return await response.json();
}

export async function updateUserStatus(userId, status) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/users/${userId}/status`, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
		body: JSON.stringify({ status }),
	});

	if (!response.ok) {
		throw new Error('Failed to update user status');
	}
}

export async function updateUserIDV(userId) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/users/${userId}/idv`, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to update user idv');
	}
}

export async function updateUserSelfExclusion(userId, endDate) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.crmApiUrl}/users/${userId}/self-exclusion`,
		{
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
			body: JSON.stringify({ endDate }),
		}
	);

	if (!response.ok) {
		throw new Error('Failed to update user self exclusion');
	}
}

export async function deleteUser(userId) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/users/${userId}`, {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to delete user');
	}
}

export async function createNote(userId, note) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/users/${userId}/note`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
		body: JSON.stringify({ note }),
	});

	if (!response.ok) {
		throw new Error('Failed to create note');
	}
}

export async function getDashboardStats() {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.reportingApiUrl}/dashboard`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to get dashboard stats');
	}

	return await response.json();
}

export async function getLotteryDashboard() {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.reportingApiUrl}/dashboard/lottery`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error('Failed to get lottery data');
	}

	return await response.json();
}

export async function getAffiliateDashboard(from, to) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.reportingApiUrl}/dashboard/affiliate?from=${from}&to=${to}`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error('Failed to get affiliate data');
	}

	return await response.json();
}

export async function getEmailDashboard(from, to) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.reportingApiUrl}/dashboard/email?from=${from}&to=${to}`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error('Failed to get email data');
	}

	return await response.json();
}

export async function getMarketingEmails() {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.reportingApiUrl}/marketing-emails`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to get marketing emails');
	}

	return await response.text();
}

export async function getTeams() {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/teams`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to get teams');
	}

	return await response.json();
}

export async function getTeam(teamId) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/teams/${teamId}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to get team');
	}

	return await response.json();
}

export async function updateTeam(request) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.crmApiUrl}/teams/${request.teamId}`,
		{
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
			body: JSON.stringify(request),
		}
	);

	if (!response.ok) {
		throw new Error('Failed to update team');
	}
}

export async function insertPush(request) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.pushNotificationApiUrl}/schedule`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
		body: JSON.stringify(request),
	});

	if (!response.ok) {
		throw new Error('Failed to save push');
	}
}

export async function createPublicCircl(request) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/circls/public`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
		body: JSON.stringify(request),
	});

	if (!response.ok) {
		throw new Error('Failed to create public circl');
	}
}

export async function updatePublicCircl(circlId) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.crmApiUrl}/circls/public/${circlId}`,
		{
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error('Failed to update public circl');
	}
}

export async function getPushSchedule() {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.pushNotificationApiUrl}/schedule`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to get push schedule');
	}

	return response.json();
}

export async function testPush(request) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.pushNotificationApiUrl}/test`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
		body: JSON.stringify(request),
	});

	if (!response.ok) {
		throw new Error('Failed to test push');
	}
}

export async function deletePush(id) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(
		`${config.pushNotificationApiUrl}/schedule/${id}`,
		{
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
			},
		}
	);

	if (!response.ok) {
		throw new Error('Failed to delete push');
	}
}

export async function deleteCard(cardId) {
	const authUser = await Auth.currentAuthenticatedUser();

	const response = await fetch(`${config.crmApiUrl}/users/card/${cardId}`, {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Failed to delete card');
	}
}
