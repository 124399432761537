import React from 'react';
import styled from 'styled-components';
import { Input as MantineInput } from '@mantine/core';

const Group = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px 0;

	label {
		font-weight: 700;
		margin-bottom: 5px;
	}
`;

const Input = (props) => {
	return (
		<Group>
			<label>{props.label}</label>
			<MantineInput {...props}>{props.value}</MantineInput>
		</Group>
	);
};

export default Input;
