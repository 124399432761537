import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import { Card, Loader, Table } from '@mantine/core';
import Field from '../components/Field';
import { getCircl } from '../utils/api';
import {
	formatCurrency,
	formatDateTime,
	getGameType,
	getPeriod,
} from '../utils/utils';
import moment from 'moment';
import { DefaultTheme } from '../styles/DefaultTheme';

const FieldGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;

	@media (max-width: 500px) {
		grid-template-columns: 1fr;
	}
`;

const Circl = () => {
	let { circlId } = useParams();
	const [circl, setCircl] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function init() {
			const circl = await getCircl(circlId);
			setCircl(circl);
			setLoading(false);
		}

		init();
	}, []);

	function getStatus(status) {
		switch (status) {
			case 1:
				return 'Invited';
			case 2:
				return 'Joined';
		}
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Circl Details'} />
			<Card shadow={'md'}>
				<FieldGrid>
					<Field label={'Name'} value={circl.name} />
					<div
						onClick={() =>
							(window.location.href = `/fixture/${circl.matchId}`)
						}
					>
						<Field
							label={'Match'}
							value={circl.description}
							style={{
								cursor: 'pointer',
								textDecoration: 'underline',
							}}
						/>
					</div>
					<Field
						label={'Game Type'}
						value={getGameType(circl.mechanismType)}
					/>
					<Field label={'Rule Set'} value={circl.gameType} />
					<Field label={'Period'} value={getPeriod(circl.period)} />
					<Field
						label={'Stake'}
						value={formatCurrency(circl.buyIn)}
					/>
					<Field
						label={'Pot'}
						value={formatCurrency(
							circl.buyIn * circl.numberOfPlayers
						)}
					/>
					<Field
						label={'Created On'}
						value={moment(circl.createdOn).format(
							'DD/MM/YYYY HH:mm'
						)}
					/>
				</FieldGrid>

				{circl.payouts?.length > 0 && (
					<>
						<h2>Payouts</h2>

						<Table striped={true} highlightOnHover={true}>
							<thead>
								<tr>
									<th>Forename</th>
									<th>Surname</th>
									<th>Amount</th>
									<th>Type</th>
									<th>Match Min</th>
								</tr>
							</thead>
							<tbody>
								{circl.payouts?.map((payout) => {
									return (
										<tr>
											<td>{payout.forename}</td>
											<td>{payout.surname}</td>
											<td>
												{formatCurrency(payout.amount)}
											</td>
											<td>{payout.name}</td>
											<td>{payout.matchTimeMin}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</>
				)}

				{circl.chat?.length > 0 && (
					<>
						<h2>Chat</h2>

						<div
							style={{
								overflowY: 'scroll',
								maxHeight: 200,
								display: 'block',
							}}
						>
							<Table
								striped={true}
								highlightOnHover={true}
								style={{ maxHeight: 150 }}
							>
								<thead>
									<tr>
										<th>Name</th>
										<th>Message</th>
										<th>Time</th>
									</tr>
								</thead>
								<tbody>
									{circl.chat?.map((chat) => {
										return (
											<tr>
												<td>
													{chat.forename}{' '}
													{chat.surname}
												</td>

												<td>{chat.message}</td>
												<td>
													{formatDateTime(
														chat.createdOn
													)}
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</div>
					</>
				)}

				<h2>Players ({circl.users.length})</h2>

				<Table striped={true} highlightOnHover={true}>
					<thead>
						<tr>
							<th>Forename</th>
							<th>Surname</th>
							<th>Status</th>
							<th>Chat Muted</th>
							<th>Events Muted</th>
							<th>Delay (secs)</th>
						</tr>
					</thead>
					<tbody>
						{circl.users.map((user) => {
							return (
								<tr
									onClick={() =>
										(window.location.href = `/user/${user.userId}`)
									}
									style={{
										cursor: 'pointer',
										backgroundColor:
											circl.winnerUserId === user.userId
												? DefaultTheme.colors.secondary
														.green
												: 'white',
									}}
								>
									<td>{user.forename}</td>
									<td>{user.surname}</td>
									<td>{getStatus(user.status)}</td>
									<td>{user.muteChat ? 'Yes' : 'No'}</td>
									<td>{user.muteEvents ? 'Yes' : 'No'}</td>
									<td>{user.eventDelay}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Card>
		</>
	);
};

export default Circl;
