import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageHeader from '../components/PageHeader';
import { Card, Loader, Table } from '@mantine/core';
import { getDashboardStats } from '../utils/api';
import { formatCurrency } from '../utils/utils';
import moment from 'moment';

const DashContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const CountText = styled.span`
	font-weight: 800;
`;

const PercentagePositive = styled.span`
	color: green;
`;

const PercentageNegative = styled.span`
	color: red;
`;

const TableStyled = styled(Table)`
	display: block;
	overflow-x: auto;
	white-space: nowrap;
	width: 100%;
`;

const Dashboard = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function init() {
			const data = await getDashboardStats();
			setData(data);
			setLoading(false);
		}

		init();
	}, []);

	function formatPercentage(num) {
		return Number(num / 100).toLocaleString(undefined, {
			style: 'percent',
			minimumFractionDigits: 0,
		});
	}

	function getPercentageChange(oldNumber, newNumber, flip) {
		const val = (1 - oldNumber / newNumber) * -1 * 100;

		if (isNaN(val) || !isFinite(val)) {
			return 0;
		}

		if (!flip) {
			if (val < 0) {
				return (
					<PercentageNegative>
						{formatPercentage(val)}
					</PercentageNegative>
				);
			} else {
				return (
					<PercentagePositive>
						+{formatPercentage(val)}
					</PercentagePositive>
				);
			}
		} else {
			if (val < 0) {
				return (
					<PercentagePositive>
						{formatPercentage(val)}
					</PercentagePositive>
				);
			} else {
				return (
					<PercentageNegative>
						+{formatPercentage(val)}
					</PercentageNegative>
				);
			}
		}
	}

	function renderRow(title, data, prop, showChange, formatMoney) {
		return (
			<tr>
				<td>{title}</td>
				{data.map((x, i) => (
					<td
						style={{
							textAlign: 'center',
						}}
					>
						<CountText>
							{formatMoney ? formatCurrency(x[prop]) : x[prop]}
						</CountText>
						{showChange && (
							<>
								<br />
								{i > 0 && (
									<>
										(
										{getPercentageChange(
											x[prop],
											data[i - 1][prop]
										)}
										)
									</>
								)}
							</>
						)}
					</td>
				))}
			</tr>
		);
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Dashboard'} />

			<DashContainer>
				<TableStyled
					striped
					highlightOnHover
					withBorder
					withColumnBorders
				>
					<thead>
						<tr>
							<td></td>
							{data.registrations.map((x) => (
								<td
									style={{
										padding: '0 15px',
										textAlign: 'center',
									}}
								>
									{moment(x.registeredMonth).format('MMM YY')}
								</td>
							))}
						</tr>
					</thead>
					<tbody>
						{renderRow(
							'Spend',
							data.marketingSpend,
							'amount',
							false,
							true
						)}
						{renderRow(
							'Registrations',
							data.registrations,
							'count',
							true
						)}
						<tr>
							<td>Referrals</td>
							{data.referrals.map((x, i) => {
								const registrations =
									data.registrations[i].count;

								return (
									<td
										style={{
											textAlign: 'center',
										}}
									>
										<CountText>{x.count}</CountText>

										<br />
										{i > 0 && (
											<>
												{formatPercentage(
													(x.count /
														data.registrations[i]
															.count) *
														100
												)}
											</>
										)}
									</td>
								);
							})}
						</tr>
						<tr>
							<td>CPR</td>
							{data.registrations.map((x, i) => {
								const thisMonth =
									data.marketingSpend[i].amount / x.count;
								const lastMonth =
									i == 0
										? 0
										: data.marketingSpend[i - 1].amount /
										  data.registrations[i - 1].count;
								return (
									<td
										style={{
											textAlign: 'center',
										}}
									>
										<CountText>
											{formatCurrency(thisMonth)}
										</CountText>

										<br />
										{i > 0 && (
											<>
												{getPercentageChange(
													thisMonth,
													lastMonth,
													true
												)}
											</>
										)}
									</td>
								);
							})}
						</tr>

						<tr>
							<td>Passed IDV</td>
							{data.passedIDV.map((x, i) => {
								return (
									<td
										style={{
											textAlign: 'center',
										}}
									>
										<CountText>{x.count}</CountText>

										<br />

										<>
											{formatPercentage(
												(x.count /
													data.registrations[i]
														.count) *
													100
											)}
										</>
									</td>
								);
							})}
						</tr>

						<tr>
							<td>Depositing Users</td>
							{data.depositingUsers.map((x, i) => {
								return (
									<td
										style={{
											textAlign: 'center',
										}}
									>
										<CountText>{x.count}</CountText>

										<br />

										<>
											{formatPercentage(
												(x.count /
													data.registrations[i]
														.count) *
													100
											)}
										</>
									</td>
								);
							})}
						</tr>

						<tr>
							<td>CPA</td>
							{data.depositingUsers.map((x, i) => {
								const thisMonth =
									data.marketingSpend[i].amount / x.count;
								const lastMonth =
									i == 0
										? 0
										: data.marketingSpend[i - 1].amount /
										  data.depositingUsers[i - 1].count;
								return (
									<td
										style={{
											textAlign: 'center',
										}}
									>
										<CountText>
											{formatCurrency(thisMonth)}
										</CountText>

										<br />
										{i > 0 && (
											<>
												{getPercentageChange(
													thisMonth,
													lastMonth,
													true
												)}
											</>
										)}
									</td>
								);
							})}
						</tr>

						{renderRow(
							'Total Entries',
							data.totalEntries,
							'count',
							true
						)}

						{renderRow(
							'Entries (Private)',
							data.privateEntries,
							'count',
							true
						)}

						{renderRow(
							'Entries (Freeroll)',
							data.freerollEntries,
							'count',
							true
						)}

						{renderRow(
							'Entries (Public P2P)',
							data.p2pEntries,
							'count',
							true
						)}

						{renderRow(
							'Entries (FMM)',
							data.fmmEntries,
							'count',
							true
						)}

						{renderRow(
							'Unique Players',
							data.uniquePlayers,
							'count',
							true
						)}

						{renderRow(
							'Unique Players (Paid)',
							data.paidUniquePlayers,
							'count',
							true
						)}

						{renderRow(
							'Free Bet Stakes',
							data.freeBetStakes,
							'sum',
							true
						)}

						{renderRow('Cash Stakes', data.cashStakes, 'sum', true)}

						{renderRow('Deposits', data.deposits, 'sum', true)}

						{renderRow(
							'Withdrawals',
							data.withdrawals,
							'sum',
							true
						)}
					</tbody>
				</TableStyled>
			</DashContainer>
		</>
	);
};

export default Dashboard;
