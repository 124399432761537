import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import { Button, Card, Input, Loader, Table } from '@mantine/core';
import Field from '../components/Field';
import { TableStyles } from '../styles/DefaultTheme';
import { getTeam, updateTeam } from '../utils/api';

const FieldGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;

	@media (max-width: 500px) {
		grid-template-columns: 1fr;
	}
`;

const Team = () => {
	let { teamId } = useParams();
	const [team, setTeam] = useState();
	const [initTeam, setInitTeam] = useState();
	const [loading, setLoading] = useState(true);
	const [editMode, setEditMode] = useState(false);

	useEffect(() => {
		getData();
	}, []);

	async function getData() {
		const team = await getTeam(teamId);
		setTeam(team);
		setInitTeam(team);
		setLoading(false);
	}

	async function update() {
		setLoading(true);

		await updateTeam(team);

		setEditMode(false);

		await getData();
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={team.teamShortName} />
			<Card shadow={'md'}>
				<div style={{ marginBottom: 30 }}>
					{editMode ? (
						<>
							<Button
								onClick={update}
								style={{ width: 100 }}
								color={'green'}
							>
								Save
							</Button>
							<Button
								onClick={() => {
									setTeam(initTeam);
									setEditMode(false);
								}}
								style={{ width: 100, marginLeft: 30 }}
								color={'red'}
							>
								Cancel
							</Button>
						</>
					) : (
						<Button
							onClick={() => setEditMode(true)}
							style={{ width: 100 }}
						>
							Edit
						</Button>
					)}
				</div>
				<FieldGrid>
					<Field label={'Code'} value={team.teamCode} />
					<Field />

					<Field
						label={'Defence'}
						value={team.defence}
						onChange={(event) =>
							setTeam({ ...team, defence: event.target.value })
						}
						editMode={editMode}
					/>

					<Field
						label={'Midfield'}
						value={team.midfield}
						onChange={(event) =>
							setTeam({ ...team, midfield: event.target.value })
						}
						editMode={editMode}
					/>
					<Field
						label={'Attack'}
						value={team.attack}
						onChange={(event) =>
							setTeam({ ...team, attack: event.target.value })
						}
						editMode={editMode}
					/>
					<Field
						label={'Overall'}
						value={team.overall}
						onChange={(event) =>
							setTeam({ ...team, overall: event.target.value })
						}
						editMode={editMode}
					/>
					<Field
						label={'Home Primary'}
						value={team.homePrimary}
						onChange={(event) =>
							setTeam({
								...team,
								homePrimary: event.target.value,
							})
						}
						editMode={editMode}
					/>
					<Field
						label={'Home Secondary'}
						value={team.homeSecondary}
						onChange={(event) =>
							setTeam({
								...team,
								homeSecondary: event.target.value,
							})
						}
						editMode={editMode}
					/>
					<Field
						label={'Home Font'}
						value={team.homeFont}
						onChange={(event) =>
							setTeam({
								...team,
								homeFont: event.target.value,
							})
						}
						editMode={editMode}
					/>
					<Field
						label={'Away Primary'}
						value={team.awayPrimary}
						onChange={(event) =>
							setTeam({
								...team,
								awayPrimary: event.target.value,
							})
						}
						editMode={editMode}
					/>
					<Field
						label={'Away Secondary'}
						value={team.awaySecondary}
						onChange={(event) =>
							setTeam({
								...team,
								awaySecondary: event.target.value,
							})
						}
						editMode={editMode}
					/>
					<Field
						label={'Away Font'}
						value={team.awayFont}
						onChange={(event) =>
							setTeam({
								...team,
								awayFont: event.target.value,
							})
						}
						editMode={editMode}
					/>
					<Field label={'Goals'} value={team.goals} />
					<Field label={'Yellow Cards'} value={team.yellowCards} />
					<Field label={'Red Cards'} value={team.redCards} />
				</FieldGrid>

				<h3>Fixtures/Results</h3>

				<Table
					striped={true}
					highlightOnHover={true}
					style={TableStyles.clickable}
				>
					<thead>
						<tr>
							<th>Description</th>
							<th>Match Time</th>
							<th>Home Score</th>
							<th>Away Score</th>
							<th>Status</th>
							<th>Venue</th>
							<th>Started</th>
							<th>Featured</th>
							<th>Channel</th>
						</tr>
					</thead>
					<tbody>
						{team.fixtures.map((fixture) => {
							return (
								<tr
									onClick={() =>
										(window.location.href = `/fixture/${fixture.matchId}`)
									}
								>
									<td>{fixture.description}</td>
									<td>
										{moment(fixture.matchDateTime).format(
											'DD/MM/YYYY HH:mm'
										)}
									</td>
									<td>
										{fixture.started
											? fixture.homeScore
											: '-'}
									</td>
									<td>
										{fixture.started
											? fixture.awayScore
											: '-'}
									</td>
									<td>{fixture.status}</td>
									<td>{fixture.venue}</td>
									<td>{fixture.started ? 'Yes' : 'No'}</td>
									<td>{fixture.featured ? 'Yes' : 'No'}</td>
									<td>{fixture.channel}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Card>
		</>
	);
};

export default Team;
