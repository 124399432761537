import React, { useState, useEffect } from 'react';
import { DatePicker, TimeInput } from '@mantine/dates';
import Input from '../components/Input';
import PageHeader from '../components/PageHeader';
import {
	Card,
	Button,
	Loader,
	Switch,
	Table,
	MultiSelect,
	Alert,
	Select,
} from '@mantine/core';
import { TableStyles } from '../styles/DefaultTheme';
import {
	deletePush,
	getFixtures,
	getLotteries,
	getPublicCircls,
	getPushSchedule,
	insertPush,
	testPush,
} from '../utils/api';
import moment from 'moment';
import config from '../utils/config';
import { formatDateTime } from '../utils/utils';

const PushSchedule = () => {
	const [loading, setLoading] = useState(true);
	const [sent, setSent] = useState(false);
	const [schedule, setSchedule] = useState([]);
	const [testUsers, setTestUsers] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const [fixtures, setFixtures] = useState([]);
	const [publicCircls, setPublicCircls] = useState([]);
	const [lotteries, setLotteries] = useState([]);
	const [pushRequest, setPushRequest] = useState({
		marketingOptIn: false,
		generalNotifications: false,
	});
	const screens = [{ label: 'Fixtures', value: 'Fixtures' }, { label: 'FMM Entry', value: 'CreateTicket' }];

	useEffect(() => {
		init();

		async function init() {
			const [fixtures, publicCircls, lotteries] = await Promise.all([
				getFixtures(
					moment().startOf('day').toISOString(),
					moment().add(1, 'years').endOf('day').toISOString()
				),
				getPublicCircls(
					moment().startOf('day').toISOString(),
					moment().add(1, 'month').endOf('day').toISOString()
				),
				getLotteries(),
				getData(false),
			]);

			setFixtures(
				fixtures.map((x) => {
					return {
						label: `${x.description} - ${moment(
							x.matchDateTime
						).format('DD/MM/YYYY')}`,
						value: x.matchId,
					};
				})
			);

			setPublicCircls(
				publicCircls.map((x) => {
					return {
						label: `${x.description} - ${moment(
							x.matchDateTime
						).format('DD/MM/YYYY')}`,
						value: x.circlId,
					};
				})
			);

			setLotteries(lotteries.map((x) => {
				return {
					label: `${moment(
						x.startDate
					).format('DD/MM/YYYY')}`,
					value: x.lotteryId,
				};
			}))
		}
	}, []);

	async function getData(sent) {
		const schedule = await getPushSchedule();
		setSchedule(schedule.filter((x) => x.sent === sent));

		setLoading(false);
	}

	async function save() {
		setLoading(true);

		await insertPush({
			...pushRequest,
			sendAt: moment(
				`${pushRequest.runAtDate} ${pushRequest.runAtTime}`,
				'DD/MM/YYYY HH:mm'
			).toISOString(),
		});

		setPushRequest({
			marketingOptIn: false,
			generalNotifications: false,
		});

		await getData(sent);

		setLoading(false);
	}

	async function test() {
		await testPush({ ...pushRequest, testUsers });
		setShowAlert(true);

		setTimeout(() => setShowAlert(false), 3000);
	}

	async function onDeletePush(id) {
		setLoading(true);

		await deletePush(id);

		await getData(sent);

		setLoading(false);
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Push Notification Schedule'} />
			{showAlert && <Alert title="Test sent!" color="green" />}

			<Card shadow={'md'}>
				<Input
					label={'Title'}
					onChange={(event) =>
						setPushRequest({
							...pushRequest,
							title: event.target.value,
						})
					}
				/>

				<Input
					label={'Body'}
					onChange={(event) =>
						setPushRequest({
							...pushRequest,
							body: event.target.value,
						})
					}
				/>

				<Switch
					label="Marketing Opt In"
					style={{ margin: '20px 0' }}
					onChange={(event) =>
						setPushRequest({
							...pushRequest,
							marketingOptIn: event.currentTarget.checked,
						})
					}
				/>

				<Switch
					label="General Notification"
					style={{ margin: '20px 0' }}
					onChange={(event) =>
						setPushRequest({
							...pushRequest,
							generalNotifications: event.currentTarget.checked,
						})
					}
				/>

				<DatePicker
					placeholder="Pick date"
					label="Date"
					required
					value={pushRequest.runAtDate}
					onChange={(value) =>
						setPushRequest({
							...pushRequest,
							runAtDate: moment(value).format('DD/MM/YYYY'),
						})
					}
				/>

				<TimeInput
					placeholder="Pick time"
					label="Time"
					required
					onChange={(value) =>
						setPushRequest({
							...pushRequest,
							runAtTime: moment(value).format('HH:mm'),
						})
					}
				/>

				<Select
					label="Fixture"
					placeholder="Pick one"
					data={fixtures}
					searchable
					onChange={(value) =>
						setPushRequest({
							...pushRequest,
							matchId: value,
						})
					}
				/>

				<Select
					label="Pubic Circl"
					placeholder="Pick one"
					data={publicCircls}
					searchable
					onChange={(value) =>
						setPushRequest({
							...pushRequest,
							circlId: value,
						})
					}
				/>

				<Select
					label="FMM"
					placeholder="Pick one"
					data={lotteries}
					searchable
					onChange={(value) =>
						setPushRequest({
							...pushRequest,
							lotteryId: value,
						})
					}
				/>

				<Select
					label="Screen"
					placeholder="Pick one"
					data={screens}
					onChange={(value) =>
						setPushRequest({
							...pushRequest,
							screen: value,
						})
					}
				/>

				<MultiSelect
					data={config.pushTestUsers}
					label="Test Users"
					placeholder={'Select test users'}
					onChange={setTestUsers}
				/>

				<Button
					onClick={test}
					disabled={
						!pushRequest.title ||
						!pushRequest.body ||
						testUsers.length === 0
					}
					style={{ margin: '20px 20px 20px 0' }}
					variant="outline"
				>
					Test
				</Button>

				<Button
					onClick={save}
					disabled={
						!pushRequest.title ||
						!pushRequest.body ||
						!pushRequest.runAtDate ||
						!pushRequest.runAtTime
					}
					style={{ margin: '20px 0' }}
				>
					Save
				</Button>

				<Switch
					label="Sent?"
					onChange={(event) => {
						setSent(event.currentTarget.checked);
						getData(event.currentTarget.checked);
					}}
					style={{ margin: '20px 0' }}
				/>

				<div
					style={{
						overflowY: 'scroll',
						maxHeight: 300,
						display: 'block',
					}}
				>
					<Table striped={true} style={TableStyles.clickable}>
						<thead>
							<tr>
								<th>Send At</th>
								<th>Title</th>
								<th>Body</th>
								<th>Sent</th>
								<th>Marketing Opt In</th>
								<th>General Notification</th>
								<th>Fixture</th>
								<th>Created On</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{schedule.map((x) => {
								return (
									<tr>
										<td>{formatDateTime(x.sendAt)}</td>
										<td>{x.title}</td>
										<td>{x.body}</td>
										<td>{x.sent ? 'Yes' : 'No'}</td>
										<td>
											{x.marketingOptIn ? 'Yes' : 'No'}
										</td>
										<td>
											{x.generalNotifications
												? 'Yes'
												: 'No'}
										</td>
										<td>
											{x.description
												? `${x.description} - ${moment(
														x.matchDateTime
												  ).format('DD/MM/YYYY')}`
												: 'N/A'}
										</td>
										<td>{formatDateTime(x.createdOn)}</td>
										<td>
											{!x.sent && (
												<Button
													color={'red'}
													onClick={() =>
														onDeletePush(x.id)
													}
												>
													Delete
												</Button>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>
			</Card>
		</>
	);
};

export default PushSchedule;
