const ENV = {
	test: {
		aws_project_region: 'eu-west-1',
		aws_cognito_identity_pool_id:
			'eu-west-1:bc41ad3e-2bd1-4c19-92b9-9491d10c1093',
		aws_cognito_region: 'eu-west-1',
		aws_user_pools_id: 'eu-west-1_lxeQlyXtY',
		aws_user_pools_web_client_id: 'v5l3ujhmimuerpejq2h215ruj',
		oauth: {},
	},
	prod: {
		aws_project_region: 'eu-west-1',
		aws_cognito_identity_pool_id:
			'eu-west-1:b56e165f-d829-4437-afd8-624819053e71',
		aws_cognito_region: 'eu-west-1',
		aws_user_pools_id: 'eu-west-1_DjpNnLDMl',
		aws_user_pools_web_client_id: 'm5d9n66c9p3noadamveorntvm',
		oauth: {},
	},
};

const getConfig = () => {
	if (process.env.REACT_APP_HOST_ENV == 'prod') {
		return ENV.prod;
	} else {
		return ENV.test;
	}
};

const config = getConfig();

export default config;
