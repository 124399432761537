import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageHeader from '../components/PageHeader';
import { Loader, Table, Button } from '@mantine/core';
import { getAffiliateDashboard } from '../utils/api';
import { formatCurrency } from '../utils/utils';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const DashContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const TableStyled = styled(Table)`
	display: block;
	overflow-x: auto;
	white-space: nowrap;
	width: 100%;
`;

const TableHeader = styled.th`
	font-weight: 500;
	margin: 0 10px;
`;

const SearchRow = styled.div`
	display: grid;
	grid-template-columns: 2fr 2fr 1fr;
	align-items: center;
	margin-bottom: 20px;

	@media (max-width: 500px) {
		grid-template-columns: 1fr;
		gap: 20px;
	}

	span {
		margin-right: 20px;
	}
`;

const AffiliateDashboard = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const [fromDate, setFromDate] = useState(
		moment().startOf('month').toDate()
	);
	const [toDate, setToDate] = useState(moment().endOf('month').toDate());

	useEffect(() => {
		getData();
	}, []);

	async function getData() {
		const data = await getAffiliateDashboard(
			moment(fromDate).startOf('day').toISOString(),
			moment(toDate).endOf('day').toISOString()
		);
		setData(data);
		setLoading(false);
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Affiliates'} />

			<SearchRow>
				<div>
					<span>From:</span>
					<DatePicker
						selected={fromDate}
						onChange={(date) => setFromDate(date)}
						dateFormat="dd/MM/yyyy"
					/>
				</div>
				<div>
					<span>To:</span>
					<DatePicker
						selected={toDate}
						onChange={(date) => setToDate(date)}
						dateFormat="dd/MM/yyyy"
					/>
				</div>
				<Button onClick={getData}>Search</Button>
			</SearchRow>

			<DashContainer>
				<TableStyled
					striped
					highlightOnHover
					withBorder
					withColumnBorders
				>
					<thead>
						<tr>
							<TableHeader>UTM Campaign</TableHeader>
							<TableHeader>Registrations</TableHeader>
							<TableHeader>Deposits</TableHeader>
							<TableHeader>Sign up &gt; Deposit</TableHeader>
							<TableHeader>Base Rate</TableHeader>
							<TableHeader>Commission Rate</TableHeader>
							<TableHeader>Commission</TableHeader>
							<TableHeader>Total Spend</TableHeader>
							<TableHeader>CPR</TableHeader>
							<TableHeader>CPA</TableHeader>
						</tr>
					</thead>
					<tbody>
						{data.map((a) => {
							const totalSpend =
								parseInt(a.commission) + parseInt(a.baseRate);
							return (
								<tr>
									<td>{a.utmCampaign}</td>
									<td>{a.registrations}</td>

									<td>{a.deposited}</td>
									<td>
										{(
											(a.deposited / a.registrations) *
											100
										).toFixed(2)}
										%
									</td>
									<td>{formatCurrency(a.baseRate)}</td>
									<td>{formatCurrency(a.commissionRate)}</td>
									<td>{formatCurrency(a.commission)}</td>
									<td>{formatCurrency(totalSpend)}</td>
									<td>
										{formatCurrency(
											totalSpend / a.registrations
										)}
									</td>
									<td>
										{formatCurrency(
											totalSpend / a.deposited
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</TableStyled>
			</DashContainer>
		</>
	);
};

export default AffiliateDashboard;
