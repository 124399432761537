import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import { Card, Loader, Input, PasswordInput, Button } from '@mantine/core';
import { Auth } from 'aws-amplify';

const Login = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [config, setConfig] = useState({});
	const [loginFailed, setLoginFailed] = useState(false);

	useEffect(() => {
		console.log('ENV.: ', process.env.REACT_APP_HOST_ENV);
	}, []);

	async function login() {
		try {
			setLoading(true);
			await Auth.signIn(config.email, config.password);
			setLoading(false);
			history.push('/');
		} catch (e) {
			console.error(e);
			setLoginFailed(true);
			setLoading(false);
		}
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Login'} />
			<Card shadow={'md'}>
				<span style={{ fontWeight: 500, fontSize: 14 }}>Email</span>
				<Input
					placeholder={'Email'}
					required
					onChange={(event) =>
						setConfig({ ...config, email: event.target.value })
					}
					style={{ marginTop: 5 }}
				/>

				<PasswordInput
					placeholder="Password"
					label="Password"
					required
					onChange={(event) =>
						setConfig({ ...config, password: event.target.value })
					}
					style={{ marginTop: 20 }}
				/>

				{loginFailed && (
					<div style={{ color: 'red', marginTop: 20 }}>
						Failed to login
					</div>
				)}

				<Button style={{ marginTop: 20 }} onClick={login}>
					Login
				</Button>
			</Card>
		</>
	);
};

export default Login;
