import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Input from '../components/Input';
import PageHeader from '../components/PageHeader';
import { Card, Button, Loader } from '@mantine/core';
import Field from '../components/Field';
import { getFixture, updateFixture } from '../utils/api';
import moment from 'moment';
import { formatDateTime, formatTime } from '../utils/utils';

const FieldGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;

	@media (max-width: 500px) {
		grid-template-columns: 1fr;
	}
`;

const ChannelContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
`;

const Fixture = () => {
	let { matchId } = useParams();
	const [fixture, setFixture] = useState();
	const [loading, setLoading] = useState(true);
	const [editing, setEditing] = useState(true);
	const [channel, setChannel] = useState(null);

	useEffect(() => {
		getData();
	}, []);

	async function getData() {
		const fixture = await getFixture(matchId);
		setFixture(fixture);
		setLoading(false);
		setEditing(false);
	}

	async function update(fixture) {
		setLoading(true);
		await updateFixture(matchId, fixture);
		await getData();
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader
				title={fixture.description}
				subtitle={moment(fixture.matchDateTime).format(
					'ddd Do MMM yyyy - HH:mm'
				)}
			/>
			<Card shadow={'md'}>
				{fixture.featured ? (
					<Button
						style={{ marginBottom: 20 }}
						color={'red'}
						onClick={() => update({ ...fixture, featured: false })}
					>
						Unmark Featured
					</Button>
				) : (
					<Button
						style={{ marginBottom: 20 }}
						onClick={() => update({ ...fixture, featured: true })}
					>
						Mark Featured
					</Button>
				)}
				<FieldGrid>
					{!editing ? (
						<ChannelContainer>
							<Field label={'Channel'} value={fixture.channel} />
							<Button
								onClick={() => setEditing(true)}
								style={{ marginLeft: 30 }}
							>
								Edit
							</Button>
						</ChannelContainer>
					) : (
						<ChannelContainer>
							<Input
								label={'Channel'}
								onChange={(event) =>
									setChannel(event.target.value)
								}
							/>
							<Button
								onClick={() => update({ ...fixture, channel })}
								style={{ margin: '0 20px' }}
							>
								Confirm
							</Button>
							<Button
								color={'red'}
								onClick={() => setEditing(false)}
							>
								Cancel
							</Button>
						</ChannelContainer>
					)}
					<Field />
					<Field label={'Home Team'} value={fixture.homeTeam} />
					<Field label={'Away Team'} value={fixture.awayTeam} />
					<Field label={'Home Score'} value={fixture.homeScore} />
					<Field label={'Away Score'} value={fixture.awayScore} />
					<Field
						label={'Competition'}
						value={fixture.competitionName}
					/>
					<Field label={'Venue'} value={fixture.venue} />
					<Field
						label={'Start Time'}
						value={formatTime(fixture.matchStartTime)}
					/>
					<Field
						label={'Half Time'}
						value={formatTime(fixture.halfTimeStartTime)}
					/>
					<Field
						label={'Second Half Kick Off'}
						value={formatTime(fixture.secondHalfStartTime)}
					/>
					<Field
						label={'End Time'}
						value={formatTime(fixture.matchEndTime)}
					/>
					<Field
						label={'Featured'}
						value={fixture.featured ? 'Yes' : 'No'}
					/>

					<Field label={'Status'} value={fixture.status} />
				</FieldGrid>

				<h3>Game Stats</h3>

				<FieldGrid>
					<Field label={'Corners'} value={fixture.cornerCount} />
					<Field label={'Subs'} value={fixture.subCount} />
					<Field label={'VAR'} value={fixture.varCount} />
					<Field label={'Offside'} value={fixture.offsideCount} />
					<Field label={'Goals'} value={fixture.goalCount} />
					<Field
						label={'Yellow Cards'}
						value={fixture.yellowCardCount}
					/>
					<Field label={'Red Cards'} value={fixture.redCardCount} />
					<Field label={'Free kicks'} value={fixture.freeKickCount} />
					<Field
						label={'Shots on target'}
						value={fixture.shotOnTargetCount}
					/>
					<Field label={'Goal kicks'} value={fixture.goalKickCount} />
					<Field label={'Throw-ins'} value={fixture.throwInCount} />
					<Field label={'Headers'} value={fixture.headerCount} />
				</FieldGrid>
			</Card>
		</>
	);
};

export default Fixture;
