import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Route, Switch, Link, useHistory, Redirect } from 'react-router-dom';
import Circl from './pages/Circl';
import Circls from './pages/Circls';
import CreatePublicCircl from './pages/CreatePublicCircl';
import Dashboard from './pages/Dashboard';
import Fixture from './pages/Fixture';
import Fixtures from './pages/Fixtures';
import Login from './pages/Login';
import MarketingEmails from './pages/MarketingEmails';
import PublicCircls from './pages/PublicCircls';
import PushSchedule from './pages/PushSchedule';
import Team from './pages/Team';
import Teams from './pages/Teams';
import Users from './pages/Users';
import UserProfile from './pages/UserProfile';
import { Drawer, Burger } from '@mantine/core';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { Auth } from 'aws-amplify';
import LotteryDashboard from './pages/LotteryDashboard';
import AffiliateDashboard from './pages/AffiliateDashboard';
import EmailDashboard from './pages/EmailDashboard';

Amplify.configure(awsconfig);

const Container = styled.div`
	margin-left: ${(props) => (props.opened ? '240px' : '0')};
	padding: 20px;

	@media (max-width: 500px) {
		margin-left: 0;
	}
`;

const SideBarItems = styled.div`
	display: flex;
	flex-direction: column;

	a {
		font-weight: 700;
		font-size: 22px;
		margin: 10px 0;
		text-align: center;
		text-decoration: none;
		color: black;

		&:visited {
			color: black;
		}

		&:hover {
			text-decoration: underline;
		}

		&:active {
			text-decoration: none;
		}
	}
`;

const SignOutLink = styled.a`
	align-self: baseline;
	position: absolute;
	bottom: 100px;
	text-align: center;
	width: 100%;
	text-decoration: underline;
	cursor: pointer;
`;

function App() {
	const history = useHistory();
	const [opened, setOpened] = useState(true);

	return (
		<>
			<Drawer
				opened={opened}
				onClose={() => {
					setOpened(false);
				}}
				padding="md"
				size="sm"
				noCloseOnClickOutside
				noFocusTrap
				noScrollLock
				noOverlay
			>
				<img
					src={process.env.PUBLIC_URL + 'logo_with_text.png'}
					style={{ width: 180, marginBottom: 50, cursor: 'pointer' }}
					onClick={() => history.push('/')}
				/>
				<SideBarItems>
					<Link to={'/'}>Dashboard</Link>
					<Link to={'/fmm'}>FMM</Link>
					<Link to={'/Affiliates'}>Affiliates</Link>
					<Link to={'/email-data'}>Email Data</Link>
					<Link to={'/users'}>Users</Link>
					<Link to={'/fixtures'}>Fixtures</Link>
					<Link to={'/public-circls'}>Public Circls</Link>
					<Link to={'/teams'}>Teams</Link>
					<Link to={'/push-schedule'}>Push Schedule</Link>
					<Link to={'/marketing-emails'}>Marketing Emails</Link>
				</SideBarItems>

				<SignOutLink
					onClick={() => {
						Auth.signOut();
						history.push('/login');
					}}
				>
					Sign out
				</SignOutLink>
			</Drawer>
			<Container opened={opened}>
				<Burger
					opened={opened}
					onClick={() => setOpened((o) => !o)}
					style={{ position: 'absolute', top: 20, left: 20 }}
				/>
				<Switch>
					<PrivateRoute path="/" component={Dashboard} exact />
					<PrivateRoute
						path="/fmm"
						component={LotteryDashboard}
						exact
					/>

					<PrivateRoute
						path="/affiliates"
						component={AffiliateDashboard}
						exact
					/>
					<PrivateRoute path="/users" component={Users} />
					<PrivateRoute
						path="/user/:userId"
						component={UserProfile}
					/>
					<PrivateRoute path="/circls" component={Circls} />
					<PrivateRoute path="/circl/:circlId" component={Circl} />
					<PrivateRoute path="/fixtures" component={Fixtures} />
					<PrivateRoute
						path="/fixture/:matchId"
						component={Fixture}
					/>
					<PrivateRoute path="/teams" component={Teams} />
					<PrivateRoute path="/team/:teamId" component={Team} />
					<PrivateRoute
						path="/push-schedule"
						component={PushSchedule}
					/>
					<PrivateRoute
						path="/public-circls"
						component={PublicCircls}
					/>
					<PrivateRoute
						path="/create-public-circl"
						component={CreatePublicCircl}
					/>
					<PrivateRoute
						path="/marketing-emails"
						component={MarketingEmails}
					/>
					<PrivateRoute
						path="/email-data"
						component={EmailDashboard}
					/>
					<Route path="/login" component={Login} />
				</Switch>
			</Container>
		</>
	);
}

function PrivateRoute({ component: Component, ...rest }) {
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function checkUser() {
			try {
				const user = await Auth.currentAuthenticatedUser();
				setUser(user);
				setLoading(false);
			} catch (e) {
				console.error(e);
				setLoading(false);
			}
		}

		checkUser();
	}, []);

	if (loading) {
		return <></>;
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				user ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/login',
						}}
					/>
				)
			}
		/>
	);
}

export default App;
