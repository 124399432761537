import { Input } from '@mantine/core';
import React from 'react';
import styled from 'styled-components';

const FieldStyled = styled.div`
	display: flex;
	flex-direction: column;

	label {
		font-weight: 700;
		margin-bottom: 5px;
		text-decoration: underline;
		text-underline-offset: 1px;
	}
`;

const Field = ({ label, value, style, onChange, editMode }) => {
	return (
		<FieldStyled style={style}>
			<label>{label}</label>
			{editMode ? (
				<Input
					value={value}
					onChange={onChange}
					style={{ width: 100 }}
				/>
			) : (
				<span>{value}</span>
			)}
		</FieldStyled>
	);
};

export default Field;
