const ENV = {
	test: {
		crmApiUrl: 'https://crm-api.playcircl.xyz',
		reportingApiUrl: 'https://reporting-api.playcircl.xyz',
		pushNotificationApiUrl: 'https://push-notifications-api.playcircl.xyz',
		pushTestUsers: [
			{ value: 34, label: 'Mark' },
			{ value: 236, label: 'Filippo' },
		],
	},
	prod: {
		crmApiUrl: 'https://crm-api.playcircl.com',
		reportingApiUrl: 'https://reporting-api.playcircl.com',
		pushNotificationApiUrl: 'https://push-notifications-api.playcircl.com',
		pushTestUsers: [
			{ value: 6, label: 'Mark' },
			{ value: 7, label: 'Tom' },
			{ value: 15, label: 'Will' },
			{ value: 36, label: 'Eve' },
		],
	},
};

const getConfig = () => {
	if (process.env.REACT_APP_HOST_ENV == 'prod') {
		return ENV.prod;
	} else {
		return ENV.test;
	}
};

const config = getConfig();

export default config;
