import React, { useEffect, useState } from 'react';
import { Table, Loader } from '@mantine/core';
import PageHeader from '../components/PageHeader';

import 'react-datepicker/dist/react-datepicker.css';
import { TableStyles } from '../styles/DefaultTheme';
import { getTeams } from '../utils/api';

const Teams = () => {
	const [teams, setTeams] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getData();
	}, []);

	async function getData() {
		const teams = await getTeams();
		setTeams(teams);
		setLoading(false);
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Teams'} />

			<Table
				striped={true}
				highlightOnHover={true}
				style={TableStyles.clickable}
			>
				<thead>
					<tr>
						<th>Name</th>
						<th>Defence</th>
						<th>Midfield</th>
						<th>Attack</th>
						<th>Overall</th>
						<th>Goals</th>
						<th>Yellow Cards</th>
						<th>Red Cards</th>
					</tr>
				</thead>
				<tbody>
					{teams.map((team) => {
						return (
							<tr
								onClick={() =>
									(window.location.href = `/team/${team.teamId}`)
								}
							>
								<td>{team.teamShortName}</td>
								<td>{team.defence}</td>
								<td>{team.midfield}</td>
								<td>{team.attack}</td>
								<td>{team.overall}</td>
								<td>{team.goals}</td>
								<td>{team.yellowCards}</td>
								<td>{team.redCards}</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</>
	);
};

export default Teams;
