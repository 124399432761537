import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageHeader from '../components/PageHeader';
import { Loader } from '@mantine/core';
import { getMarketingEmails } from '../utils/api';

const DownloadButton = styled.a`
	background-color: blue;
	color: white;
	padding: 10px 20px;
	border-radius: 60px;
	text-decoration: none;
	font-weight: 600;
`;

const MarketingEmails = () => {
	const [loading, setLoading] = useState(true);
	const [csvData, setCsvData] = useState();

	useEffect(async () => {
		const data = await getMarketingEmails();
		setCsvData(data);
		setLoading(false);
	});

	async function getEmails() {
		await getMarketingEmails();
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Marketing Emails'} />

			<DownloadButton
				href={`data:text/csv;charset=utf-8,${escape(csvData)}`}
				download="emails.csv"
			>
				Download
			</DownloadButton>
		</>
	);
};

export default MarketingEmails;
