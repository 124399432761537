import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Loader, Button } from '@mantine/core';
import PageHeader from '../components/PageHeader';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import { TableStyles } from '../styles/DefaultTheme';
import { getPublicCircls, updatePublicCircl } from '../utils/api';
import { formatCurrency } from '../utils/utils';

const SearchRow = styled.div`
	display: grid;
	grid-template-columns: 2fr 2fr 1fr 1fr;
	align-items: center;
	margin-bottom: 20px;
	gap: 10px;
	@media (max-width: 500px) {
		grid-template-columns: 1fr;
		gap: 20px;
	}

	span {
		margin-right: 20px;
	}
`;

const PublicCircls = () => {
	const [fromDate, setFromDate] = useState(moment().add(-7, 'days').toDate());
	const [toDate, setToDate] = useState(moment().add(14, 'days').toDate());
	const [circls, setCircls] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getData();
	}, []);

	async function getData() {
		const circls = await getPublicCircls(
			moment(fromDate).startOf('day').toISOString(),
			moment(toDate).endOf('day').toISOString()
		);
		setCircls(circls);
		setLoading(false);
	}

	async function onUpdate(circlId) {
		setLoading(true);

		await updatePublicCircl(circlId);

		await getData();
	}

	if (loading) {
		return (
			<Loader
				size="xl"
				style={{ position: 'fixed', top: '50%', left: '50%' }}
			/>
		);
	}

	return (
		<>
			<PageHeader title={'Public Circls'} />

			<SearchRow>
				<div>
					<span>From:</span>
					<DatePicker
						selected={fromDate}
						onChange={(date) => setFromDate(date)}
						dateFormat="dd/MM/yyyy"
					/>
				</div>
				<div>
					<span>To:</span>
					<DatePicker
						selected={toDate}
						onChange={(date) => setToDate(date)}
						dateFormat="dd/MM/yyyy"
					/>
				</div>
				<Button onClick={getData}>Search</Button>
				<Button
					onClick={() =>
						(window.location.href = '/create-public-circl')
					}
					color={'green'}
				>
					Create
				</Button>
			</SearchRow>

			<Table
				striped={true}
				highlightOnHover={true}
				style={TableStyles.clickable}
			>
				<thead>
					<tr>
						<th>Description</th>
						<th>Match Time</th>
						<th>Buy In</th>
						<th>Min Pot</th>
						<th>Actual Pot</th>
						<th>Freeroll</th>
						<th>Goal Gifter</th>
						<th>Players</th>
						<th>Max Players</th>
						<th>Display</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{circls.map((circl) => {
						return (
							<tr
								onClick={() =>
									(window.location.href = `/circl/${circl.circlId}`)
								}
							>
								<td>{circl.description}</td>
								<td>
									{moment(circl.matchDateTime).format(
										'DD/MM/YYYY HH:mm'
									)}
								</td>
								<td>{formatCurrency(circl.buyIn)}</td>
								<td>{formatCurrency(circl.pot)}</td>
								<td>
									{formatCurrency(
										Math.max(
											circl.pot,
											circl.playerCount * circl.buyIn
										)
									)}
								</td>
								<td>{circl.free ? 'YES' : 'NO'}</td>
								<td>
									{circl.mechanismType == 4 ? 'YES' : 'NO'}
								</td>
								<td>{circl.playerCount}</td>
								<td>{circl.maxPlayers}</td>
								<td>{circl.display ? 'YES' : 'NO'}</td>
								<td>
									{circl.display ? (
										<Button
											onClick={(e) => {
												e.stopPropagation();
												onUpdate(circl.circlId);
											}}
											color={'red'}
										>
											Hide
										</Button>
									) : (
										<Button
											onClick={(e) => {
												e.stopPropagation();
												onUpdate(circl.circlId);
											}}
											color={'green'}
										>
											Display
										</Button>
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</>
	);
};

export default PublicCircls;
