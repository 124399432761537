import React from 'react';
import styled from 'styled-components';

const Header = styled.h1`
	font-weight: 700;
	font-size: 26px;
	text-align: center;
`;

const SubHeader = styled.h3`
	text-align: center;
`;

const PageHeader = ({ title, subtitle }) => {
	return (
		<>
			<Header>{title}</Header>
			{subtitle && <SubHeader>{subtitle}</SubHeader>}
		</>
	);
};

export default PageHeader;
