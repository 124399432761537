import moment from 'moment';

export function formatCurrency(value) {
	return new Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: 'GBP',
	}).format(value);
}

export function formatDate(val) {
	return !val ? '' : moment(val).format('DD/MM/YYYY');
}

export function formatDateTime(val) {
	return !val ? '' : moment(val).format('DD/MM/YYYY HH:mm');
}

export function formatTime(val) {
	return !val ? '' : moment(val).format('HH:mm');
}

export function getGameType(mechanismType) {
	switch (mechanismType) {
		case 1:
			return 'Shifter';
		case 2:
			return 'Goal Gifter';
		case 3:
			return 'Public Shifter';
		case 4:
			return 'Public Goal Gifter';
		case 5:
			return 'Public Countdown';
		default:
			return '????';
	}
}

export function getPeriod(periodId) {
	switch (periodId) {
		case 1:
			return 'First Half';
		case 2:
			return 'Second Half';
		default:
			return 'Full Time';
	}
}

export function getTransactionType(typeId) {
	switch (typeId) {
		case 1:
			return 'Deposit';
		case 2:
			return 'Withdrawal';
		case 3:
			return 'Bet';
		case 4:
			return 'Win';
		default:
			return '???';
	}
}
